.mainHome{
height: 100%;
width: 100%;
// background-color: #fafafa;
// background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);

// background: rgb(201,210,217);
// background: linear-gradient(90deg, rgba(201,210,217,1) 0%, rgba(153,161,172,1) 100%);

.bottomPart{
height: 38%;
// width: 100%;
max-width: 100% !important;
background-color: #4158D0;
background-image: linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
display: flex;
justify-content: flex-end;
align-items: flex-end;
padding-right: 20%;
padding-left: 25%;


.boxes {
    display: flex;
    height: 100%;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-left: 5%;
}


.contactInfo {
    height: 100%;
    width: 20%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    // margin-right: 3%;

    .contactLine{
        display: flex;
        margin-top: 10px;
        align-items: center;

        .logo{
            color: white;
            transform: scale(1.2);
        }
        .text {
            color: white;
            font-size: x-large;
            font-weight: bold;
            margin-right: 8px;
        }
    }
}


}


.bottomFlex{
width : 100%;
height: 40%;
display: flex;
background-color: rgb(48, 48, 48);

}

}