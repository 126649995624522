.mainHeader {
    width: 100%;
    height: 60%;
    font-family: Arial, Helvetica, sans-serif;
    // background-image: url("../../images/newbg.jpg" ) ;
    // // background-size: 100% 100%;
    // object-fit: cover;
    // border-bottom:10px solid #c5c0c0;
    // background-color: red;

    .bgImage {
        width: 60%;
        height: 80%;
        object-fit: cover;
        margin-left: 20%;
        border: 10px solid rgb(248, 248, 248);
        object-position: 30 0;
        // border-bottom: solid 10px rgb(51, 51, 51);
        margin-top:3%;

    }

.navBar {
    width: 100%;
    height: 10%;
    background-color: #ffffff;
    display:flex;
    justify-content: space-between;
    font-family: 'Open Sans Hebrew Condensed', sans-serif;
    border: 10px solid;
    border-image-slice: 1;
    border-width: 3px;
    border-image-source:  linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
    border-left: 0;
    border-right: 0;
    border-bottom: 0;


    .pictureItLogo{
        margin-right: 20%;
    }

.headerOptions {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    margin-right:2%;

    .option {
        font-size:20px;
        color: rgb(36, 36, 36);
        padding-left: 2%;
        padding-right: 2%;
        cursor: pointer;
        height: 100%;
        justify-content: center;
        display:flex;
        flex-direction: column;
        border-bottom: 2px solid transparent;
    }

    .option:hover{
        border: 10px solid;
        border-image-slice: 1;
        border-width: 2px;
        border-image-source:  linear-gradient(43deg, #4158D0 0%, #C850C0 46%, #FFCC70 100%);
        border-left: 0;
        border-right: 0;
        border-top: 0;
    }


}

.headerLogos {
    display: flex;
    // width: 30%;
    align-items: center;
    justify-content: flex-end;
    margin-left:20%;
}

.logo{
    cursor: pointer;
    height: 25px;
    width: 25px;
    margin-left: .5%;
    margin-right: .5%;
}

}

}