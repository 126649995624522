.clickableBoxMain {
    height: 50%;
    width:  50%;
    border-radius: 10px;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    font-family: 'Helvetica', 'Arial', sans-serif;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    margin-left: 1%;
    margin-right: 1%;

    .clickableBoxText{
        color: white;
        font-weight: bold;
        font-size: xx-large;
        bottom: 0;
        margin-bottom: 10%

    }
    
    .clickableBoxIcon{
        transform: scale(3);
        color: rgb(218, 219, 218);
        margin-top: 12%;
    }
}

.clickableBoxMain:hover {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    font-family: 'Helvetica', 'Arial', sans-serif;
}